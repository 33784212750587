import {
    FilterInputs,
    TableCellAmount,
    TableCellShortDate,
    TableCellTransactionDirection,
    TableColumnCustomizers,
    TableStateWithEntity
} from '@/components'
import {
    AmountInput,
    BalanceTypeInput,
    BooleanInput,
    ConnectedAccountsInput,
    CurrencyInput,
    DateInput,
    PaymentDirectionInput,
    TextInput
} from '@/components/@inputs'
import { ConnectedAccountName, FormattedTextIntl } from '@/components/@misc'
import {
    COLUMN_WIDTH_RATIO,
    DOCUMENTATION_BASE_URL,
    HIGHLIGHTED_CURRENCY_CODES,
    MIN_COLUMN_WIDTH,
    WILDCARD_SYMBOL
} from '@/constants'
import { QueryParamQuickFilters, QueryParamStateFilterBy } from '@/hooks'
import { BalanceExpanded } from '@/services'
import { uuidValidator } from '@/utils/@validators'
import { Balance, BalancesServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'

export const BALANCE_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/balance-object`

export const BALANCE_TABLE_STATIC_STATE: TableStateWithEntity<Balance | BalanceExpanded> = {
    columnOrder: Object.freeze(['connected_account_id', 'direction', 'amount', 'created_at', 'date', 'type'])
}

export const BALANCE_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<Balance> = {
    connected_account_id: {
        size: COLUMN_WIDTH_RATIO.CONNECTED_ACCOUNT,
        cell(info) {
            return <ConnectedAccountName connectedAccountId={info.getValue()} asPlainText={true} />
        }
    },
    direction: {
        size: COLUMN_WIDTH_RATIO.DIRECTION,
        cell(info) {
            return <TableCellTransactionDirection value={info.getValue()} />
        }
    },
    amount: {
        size: COLUMN_WIDTH_RATIO.AMOUNT,
        cell(info) {
            const { currency } = info.row.original
            return <TableCellAmount amount={info.getValue()} currency={currency} />
        }
    },
    created_at: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        size: COLUMN_WIDTH_RATIO.SHORT_DATE,
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    date: {
        minSize: MIN_COLUMN_WIDTH.SHORT_DATE,
        size: COLUMN_WIDTH_RATIO.SHORT_DATE,
        header() {
            return <FormattedTextIntl messageId="app.table.header.value_date" />
        },
        cell(info) {
            return <TableCellShortDate value={info.getValue()} />
        }
    },
    type: {
        minSize: MIN_COLUMN_WIDTH.BALANCE_TYPE,
        cell(info) {
            return (
                <FormattedTextIntl messageId={`api.balance.type.${info.getValue()}`} defaultMessage={info.getValue()} />
            )
        }
    }
}

export const BALANCES_FILTERS: FilterInputs<BalancesServiceFindAllQueryOptions> = {
    start_date: <DateInput name="start_date" />,
    end_date: <DateInput name="end_date" />,
    type: <BalanceTypeInput name="type" />,
    direction: <PaymentDirectionInput name="direction" />,
    currency: <CurrencyInput name="currency" highlightedCurrencyCodes={HIGHLIGHTED_CURRENCY_CODES} />,
    amount_from: <AmountInput name="amount_from" />,
    amount_to: <AmountInput name="amount_to" />,
    connected_account_id: <ConnectedAccountsInput name="connected_account_id" isPlaceholderDisplayed={true} />,
    file_id: <TextInput name="file_id" validator={uuidValidator} />,
    latest: <BooleanInput name="latest" />
}

export const BALANCES_GROUP_BY = Object.freeze(['currency', 'country', 'bank_name'])

export const BALANCES_PRESELECTED_FILTERS: QueryParamStateFilterBy<BalancesServiceFindAllQueryOptions> = Object.freeze({
    latest: true
})

export const BALANCES_QUICK_FILTERS: QueryParamQuickFilters<BalancesServiceFindAllQueryOptions> = {
    latest: [true],
    [WILDCARD_SYMBOL]: [WILDCARD_SYMBOL]
}
