import {
    QueryFallback,
    RestrictedSection,
    TableHeader,
    TableHeaderActionProps,
    useTableRowNavigation
} from '@/components'
import { SupportEmailMailtoLink } from '@/components/@misc'
import {
    useCustomFieldFilters,
    useMutationExportPaymentOrders,
    useNavigationRoutes,
    usePermissions,
    useQueryFindAllPaymentOrders,
    useQueryParams,
    useQueryPaymentOrdersResolver,
    useToasts
} from '@/hooks'
import { usePage } from '@/hooks/context/usePage'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { ReconcileEntityDynamicHeader, getReconcileEntityBulkActions } from '@/pages'
import { BulkActionsProvider, BulkActionsTable, QuickFilterProvider, TOAST_VARIANTS } from '@/providers'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import {
    ApiObjectTypeSchema,
    PaymentOrder,
    PaymentOrdersServiceFindAllQueryOptions,
    Transaction
} from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import React, { PropsWithChildren, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { PaymentOrderCreateActionContainer } from './@components'
import {
    PAYMENT_ORDER_BULK_ACTIONS,
    PAYMENT_ORDER_DOCUMENTATION_LINK,
    PAYMENT_ORDER_FILTERS,
    PAYMENT_ORDER_QUICK_FILTERS,
    PAYMENT_ORDER_TABLE_COLUMN_CUSTOMIZERS,
    PAYMENT_ORDER_TABLE_STATIC_STATE
} from './PaymentOrders.page.const'

export const PaymentOrdersPage: React.FC = () => {
    const page = usePage<Transaction>()
    const intl = useIntl()
    const { hasPermission } = usePermissions()
    const { onAdd } = useToasts()
    const navigate = useNavigate()
    const { relativePaths } = useNavigationRoutes()
    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.payments.payment_orders.title' }), [intl])

    const filters = useCustomFieldFilters(ApiObjectTypeSchema.enum.payment_order, PAYMENT_ORDER_FILTERS)
    const { search, filterBy } = useQueryParams<PaymentOrdersServiceFindAllQueryOptions>('search', {
        filterBy: {
            configuration: {
                filters,
                quickFilters: PAYMENT_ORDER_QUICK_FILTERS
            }
        }
    })

    const query = useQueryFindAllPaymentOrders({ ...search.parsedState, ...filterBy.parsedState })
    const mutationExport = useMutationExportPaymentOrders({
        onSuccess(response) {
            onAdd({
                variant: TOAST_VARIANTS.INFO,
                status: 'info',
                title: <FormattedMessage id="app.common.export.success.toast.title" />,
                description: <FormattedMessage id="app.common.export.success.toast.description" />
            })
        },
        onError(error) {
            onAdd({
                variant: TOAST_VARIANTS.ERROR,
                status: 'error',
                title: <FormattedMessage id="app.common.export.error.toast.title" />,
                description: (
                    <FormattedMessage
                        id="app.common.export.error.toast.description"
                        values={{ supportEmailMailtoLink: <SupportEmailMailtoLink /> }}
                    />
                )
            })
        }
    })

    const data = useMemo<PaymentOrder[]>(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])

    const actions = useMemo(() => {
        const actions: TableHeaderActionProps[] = []

        if (page.hasActiveContext) {
            return []
        }

        actions.push({
            onClick() {
                mutationExport.mutate({ search_parameters: { ...search.parsedState, ...filterBy.parsedState } })
            },
            children: intl.formatMessage({ id: 'app.common.actions.export' }),
            isDisabled: isDataEmpty || mutationExport.isPending
        })

        if (hasPermission(PERMISSION.PAYMENTS_CREATE_PAYMENT_ORDERS)) {
            actions.push({
                Container(props: PropsWithChildren) {
                    return <PaymentOrderCreateActionContainer {...props} />
                },
                onClick() {
                    navigate(relativePaths.NEW)
                },
                children: intl.formatMessage({ id: 'app.payments.payment_orders.create.form.actions.submit.label' })
            })
        }

        return actions
    }, [
        hasPermission,
        intl,
        isDataEmpty,
        navigate,
        relativePaths.NEW,
        mutationExport,
        search.parsedState,
        filterBy.parsedState,
        page
    ])

    const onTableRowClick = useTableRowNavigation<PaymentOrder>('id', relativePaths.PAYMENTS.DETAILS)

    const bulkActions = useMemo(() => {
        return {
            ...PAYMENT_ORDER_BULK_ACTIONS,
            ...getReconcileEntityBulkActions(!page.hasActiveContext)
        }
    }, [page])

    return (
        <PageLayout className="PaymentOrders">
            <ReconcileEntityDynamicHeader>
                <PageHeaderLayout title={title} showBackButton={false} />
            </ReconcileEntityDynamicHeader>
            <RestrictedSection
                feature={ENTITY_FEATURE.PAYMENT_ORDER}
                permission={PERMISSION.PAYMENTS_VIEW_PAYMENT_ORDERS}>
                <QuickFilterProvider<PaymentOrdersServiceFindAllQueryOptions> filterBy={filterBy}>
                    <BulkActionsProvider<PaymentOrder, PaymentOrdersServiceFindAllQueryOptions>
                        targetObject={ApiObjectTypeSchema.enum.payment_order}
                        actions={bulkActions}
                        filterBy={filterBy}
                        search={search}
                        customResolver={useQueryPaymentOrdersResolver}
                        data={data}>
                        <TableHeader<PaymentOrdersServiceFindAllQueryOptions>
                            objectType={ApiObjectTypeSchema.enum.payment_order}
                            isLoading={query.isLoading}
                            actions={actions}
                            filterBy={filterBy}
                            search={search}
                        />
                        <QueryFallback
                            objectType={ApiObjectTypeSchema.enum.payment_order}
                            isLoading={query.isLoading}
                            isSuccess={query.isSuccess}
                            isError={query.isError}
                            isDataEmpty={isDataEmpty}
                            isFilteringOrSearching={!!search?.isActive || !!filterBy?.isActive}
                            learnMoreLinkUrl={PAYMENT_ORDER_DOCUMENTATION_LINK}>
                            <BulkActionsTable<PaymentOrder>
                                data={data}
                                onRowClick={onTableRowClick}
                                onScrollToBottom={query.fetchNextPage}
                                isLoading={query.hasNextPage}
                                state={PAYMENT_ORDER_TABLE_STATIC_STATE}
                                columnCustomizers={PAYMENT_ORDER_TABLE_COLUMN_CUSTOMIZERS}
                            />
                        </QueryFallback>
                    </BulkActionsProvider>
                </QuickFilterProvider>
            </RestrictedSection>
        </PageLayout>
    )
}
