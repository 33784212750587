import {
    FilterInputs,
    TableCellStatus,
    TableCellTextIntl,
    TableColumnCustomizers,
    TableStateWithEntity
} from '@/components'
import { DateInput, EventStatusInput, TextInput } from '@/components/@inputs'
import { FormattedDateTime } from '@/components/@misc'
import { DOCUMENTATION_BASE_URL, MIN_COLUMN_WIDTH } from '@/constants'
import { EventsServiceFindAllQueryOptions, NumeralEvent } from '@webapps/numeral-ui-core'

export const EVENTS_DOCUMENTATION_LINK = `${DOCUMENTATION_BASE_URL}/event-object`

export const EVENTS_TABLE_STATIC_STATE: TableStateWithEntity<NumeralEvent> = {
    columnOrder: Object.freeze(['created_at', 'topic', 'type', 'status'])
}

export const EVENTS_TABLE_COLUMN_CUSTOMIZERS: TableColumnCustomizers<NumeralEvent> = {
    created_at: {
        minSize: MIN_COLUMN_WIDTH.LONG_DATE,
        cell(info) {
            return <FormattedDateTime value={info.getValue()} />
        }
    },
    type: {
        header() {
            return <TableCellTextIntl messageId="app.table.header.event.type" />
        }
    },
    status: {
        cell(info) {
            return <TableCellStatus value={info.getValue()} />
        }
    }
}

export const EVENTS_FILTERS: FilterInputs<EventsServiceFindAllQueryOptions> = {
    start_date: <DateInput name="start_date" />,
    end_date: <DateInput name="end_date" />,
    status: <EventStatusInput name="status" />,
    topic: <TextInput name="topic" />,
    type: <TextInput name="type" />
}
